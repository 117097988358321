<template>
  <section v-if="!loading">
    <div class="d-flex align-center mx-5 mt-5 mb-10">
      <v-btn icon :to="{name: 'Admin Gamification'}">
        <v-icon large>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <div class="display-1 font-weight-bold ml-5">
        Raffle
      </div>
    </div>
    <v-row>
      <v-col cols="12" lg="4">
        <div class="body-1 font-weight-bold ma-2 success--text">Qualified</div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left">Name</th>
                <th class="text-left">Email</th>
              </tr>
            </thead>
            <tbody style="max-height: 500px; overflow: auto">
              <tr v-for="item in raffles.raffle.candidates" :key="item.id" >
                <td>
                  <v-avatar v-if="!item.user.image" size="35" color="secondary"/>
                  <v-avatar v-else size="35" color="secondary">
                    <v-img :src="`${item.user.aws}/users/${item.user.uuid}/image/${item.user.image.image}`"></v-img>
                  </v-avatar>
                </td>
                <td>{{item.user.firstname}}</td>
                <td>{{item.user.email}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <!-- {{raffles.users}} -->
      <v-col cols="12" lg="5">
        <div class="body-1 font-weight-bold ma-2 success--text">Candidates</div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-left"></th>
                <th class="text-left"></th>
                <th class="text-left">Name</th>
                <th class="text-left">Email</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in raffles.users" :key="item.id" >
                <td>
                  <v-btn icon small @click="details(item.id)">
                    <v-icon small>mdi-arrow-right</v-icon>
                  </v-btn>
                </td>

                <td :class="item.raffle? 'success' : ''" class="caption">
                  {{item.raffle ? 'Qualified' : 'Not Qualified'}}</td>
                <td>
                  <v-avatar v-if="!item.image" size="35" color="secondary"/>
                  <v-avatar v-else size="35" color="secondary">
                    <v-img :src="`${item.aws}/${item.model}/${item.uuid}/image/${item.image.image}`"></v-img>
                  </v-avatar>
                </td>
                <td>{{item.firstname + ' ' + item.lastname}}</td>
                <td>{{item.email}}</td>
               
              </tr>
            </tbody>
                      
            
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="12" lg="3" v-if="show">
        <div class="body-1 font-weight-bold ma-2 success--text">Details</div>
        <MyRank :data="user"/>
      </v-col>
    </v-row>
  </section>
  <section v-else>
    <div class="body-1">Loading...</div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
var CryptoJS = require("crypto-js");
import MyRank from '../../student/components/gaming/MyRank.vue'

export default {
  components: {
    MyRank
  },
  data: () => ({
    show: false,
    loading: true
  }),
  computed: {
    ...mapState('admin', {
      raffles: (state) => state.raffles,
      user: (state) => state.student_raffle,
    })
  },
  mounted() {
    let key = CryptoJS.AES.decrypt(this.$route.params.id, 'secret')
    this.showRaffle(key.toString(CryptoJS.enc.Utf8))
    .then(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('admin', [
      'showRaffle',
      'showStudentRaffle',
    ]),

    details(id) {
      this.showStudentRaffle(id)
      .then(() => {
        this.show = true
      })
    }
  }
}
</script>